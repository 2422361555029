/* add css module styles here (optional) */
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

._3xrlJ {
  transition-duration: 300ms;
  font-family: 'Rubik', Arial, Helvetica, sans-serif;
  background-color: #1db954;
  color: #fff;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  border: none;
  border-radius: 0.2rem;
}

._3xrlJ:hover, ._3xrlJ:focus {
  transition-duration: 500ms;
  transform: translate(-1px, 2px);
  box-shadow: 0 0.25rem 0.75rem 0 #777777;
}

._1JCP_ {
  /* Convert spotify logo to white */
  padding: 0.25rem 0.5rem;
  height: 1.5rem;
  fill: #fff;
  width:2em;
}

